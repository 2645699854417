import decode from 'jwt-decode';
// eslint-disable-next-line import/no-cycle
import auth, { authService } from '.';
// eslint-disable-next-line import/no-cycle
import logger from '../logger';

let auth0Id = null;
let userDetails = {};
const namespace = 'http://wayforward_';
  
export const setAuth0IdFromToken = (encodedToken) => {
  if (encodedToken) {
    const token = decode(encodedToken);
    if (token) {
      if (token[`${namespace}is_anonymous`] === true) {
        auth0Id = token[`${namespace}user_id`];
        return;
      }
      auth0Id = token.auth0Id || token.sub;
      logger.info(`auth0Id fetched from token is ${auth0Id}`, 'helpers.setAuth0IdFromToken');
      return;
    }
    logger.error('Decoded token empty', 'helpers.setAuth0IdFromToken');
  }
  auth0Id = null;
};

export const getTokenAndSetAuth0Id = async () => {
  try {
    const accessToken = await authService.getAccessToken();
    setAuth0IdFromToken(accessToken);
  } catch (error) {
    logger.error(
      'Error while setting auth0Id from token',
      'helpers.getTokenAndSetAuth0Id',
      { error }
    );
  }
};

export const setUserDetails = (details) => {
  userDetails = details;
};

export const getAndSetUserDetailsFromToken = async () => {
  try {
    const { features, locale, roles, connection } = await auth.getUserDetails();
    setUserDetails({ features, locale, roles, connection });
  } catch (error) {
    logger.error(
      'Error while setting user details from token',
      'helpers.getAndSetUserDetailsFromToken',
      { error }
    );
  }
};

export const getRetryConfig = (onRetry) => ({
  retries: 3,
  minTimeout: 6000,
  maxTimeout: 10000,
  factor: 2,
  onRetry
});

const isRetryable = (
  error,
  errorMessages = [
    'Empty result from Auth API',
    'Undefined Error from Auth API'
  ]
) =>
  !!(
    error.code >= 500 ||
    error.code === 429 ||
    errorMessages.includes(error.message)
  );

export const bailIfErrorNotRetryable = (bail) => (error) => {
  if (error && isRetryable(error)) {
    // retry in this case
    throw error;
  }
  // bail if retried 3 times OR the error is not retryable
  bail(error);
};

export const getAuth0Id = () => auth0Id;

export const isLoggedIn = () => !!auth0Id;

export const getUserDetails = () => userDetails;
