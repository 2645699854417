/**
 * Contains i18n logic.
 * Can be modified to detect the language from user's browser and
 * conditionally import the language file accordingly. Since we only have
 * one language right now we're just going to export that
 */

// core
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// translations
import TRANSLATIONS_EN_US from './en_US.json';
import TRANSLATIONS_ES_US from './es_US.json';
// config
// eslint-disable-next-line import/no-cycle
import config from '../../config';
// utils
// eslint-disable-next-line import/no-cycle
import { isLoggedIn, getUserDetails } from '../../utils/auth/helpers';
import supportedLanguages from './supportedLanguages';

const { fallbackLang, debugLogs, nodeEnvironment } = config;
const languageDetector = new LanguageDetector();

const tokenLocaleDetector = {
  name: 'token',

  lookup() {
    if (isLoggedIn()) {
      const { locale } = getUserDetails();
      return locale || fallbackLang;
    }
    return null;
  }
};

const brandLocaleDetector = {
  name: 'brand',

  lookup() {
    return config.brand === 'cascade' ? supportedLanguages.EN_US : undefined;
  }
};

languageDetector.addDetector(brandLocaleDetector);
languageDetector.addDetector(tokenLocaleDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    debug: nodeEnvironment === 'test' ? false : debugLogs,
    fallbackLng: fallbackLang,
    detection: {
      order: ['brand', 'token', 'navigator']
    },
    resources: {
      // strict lang-country match
      [supportedLanguages.EN_US]: {
        translation: TRANSLATIONS_EN_US
      },
      [supportedLanguages.ES_US]: {
        translation: TRANSLATIONS_ES_US
      },
      // pre fallback step: use US based languages
      en: {
        translation: TRANSLATIONS_EN_US
      },
      es: {
        translation: TRANSLATIONS_ES_US
      }
    }
  });

export default i18n;
