import { get } from 'lodash';
import { platformInfo } from 'utils/platformInfo';

const { isBrowser } = platformInfo;

const lock = get(window, 'screen.orientation.lock', null);
const unlock = get(window, 'screen.orientation.unlock', null);
const lockScreenOrientation = (orientation) => {
  if (!isBrowser && lock) {
    lock(orientation);
  }
};

const unlockScreenOrientation = () => {
  if (!isBrowser && unlock) {
    unlock();
  }
};

export { lockScreenOrientation, unlockScreenOrientation };
