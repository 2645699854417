/**
 * Reducer to hold the state of message read/ sent in a session atleast once
 */
import { SET_CHAT_MESSAGE_SENT, SET_CHAT_MESSAGE_READ } from '../actions/types';

const INITIAL_STATE = {
  chatMessageSentOrRead: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CHAT_MESSAGE_READ:
    case SET_CHAT_MESSAGE_SENT: {
      return {
        chatMessageSentOrRead: true
      };
    }
    default:
      return state;
  }
};
