import { SHOW_MODAL, CLOSE_MODAL, TOGGLE_MODAL_HIDE_BUTTON } from './types';

export const showModal = (
  component,
  {
    maxWidth,
    maxHeight,
    isScrollable,
    modalHeader,
    showFullScreenInMobile,
    props,
    onClose,
    hideCloseButton,
    source,
    showModalInCenter
  } = {}
) => ({
  type: SHOW_MODAL,
  payload: {
    component,
    maxWidth,
    maxHeight,
    isScrollable,
    modalHeader,
    showFullScreenInMobile,
    props,
    onClose,
    hideCloseButton,
    source,
    showModalInCenter
  }
});

export const toggleModalCloseButton = () => ({
  type: TOGGLE_MODAL_HIDE_BUTTON
});

export const closeModal = () => ({
  type: CLOSE_MODAL
});
