// action types
import {
  SET_APP_SETTINGS_DONE,
  UPDATE_APP_SETTINGS_DONE,
  UPDATE_APP_SETTINGS_ERROR,
  UPDATE_APP_SETTINGS_IN_PROGRESS
} from '../actions/types';

const INITIAL_STATE = {
  isUpdating: false,
  hasUpdateError: false,
  settings: {},
  devices: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_APP_SETTINGS_DONE:
      return {
        ...state,
        hasUpdateError: false,
        settings: { ...action.payload.settings },
        devices: [...action.payload.devices]
      };
    case UPDATE_APP_SETTINGS_IN_PROGRESS:
      return {
        ...state,
        isUpdating: true,
        hasUpdateError: false
      };
    case UPDATE_APP_SETTINGS_ERROR:
      return {
        ...state,
        isUpdating: false,
        hasUpdateError: true
      };
    case UPDATE_APP_SETTINGS_DONE:
      return {
        ...state,
        isUpdating: false,
        hasUpdateError: false
      };
    default:
      return state;
  }
};
