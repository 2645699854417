// contains back button handlers for root paths and/or query params

import { parse, stringify } from 'tiny-querystring';
import { isEmpty, has, get } from 'lodash';
import store, { dispatch } from 'store';
import {
  currentUserAction,
  infoTabAction
} from 'store/actions/coachchat/ActiveUserAction';
import { closeVideoModal } from 'store/actions/media/VideoPlayerModalAction';
import helpers from 'utils/helpers';
import history from 'utils/history';
import analytics from './analytics';
import logger from './logger';
import { platformInfo } from './platformInfo';

const { isMobile } = platformInfo;

const redirectToHome = () => {
  const homeUrl = helpers.homeUrl();
  logger.info('redirecting to home', 'navigation.redirectToHome', { homeUrl });
  history.replace(homeUrl);
};

export const getQueryParams = () => {
  const { currentUser } = store.getState();
  const { infoTab } = store.getState();

  let queryParams = {};
  try {
    queryParams = parse(get(window, 'location.search', '').substring(1));
    if (isEmpty(queryParams)) {
      if (!isEmpty(currentUser)) {
        queryParams.user = currentUser.to;
        if (!isEmpty(infoTab)) {
          queryParams.view = 'info';
        }
      }
    }
  } catch (error) {
    logger.error(
      'Error while parsing query parameters',
      'navigation.getQueryParams',
      { error }
    );
    history.replace({
      search: ''
    });
  }

  return queryParams;
};

const coachChatBackButtonHandler = () => {
  const queryParams = getQueryParams();
  const {
    media: {
      videoPlayerModal: { isOpen: isVideoModalOpen }
    }
  } = store.getState();

  if (isEmpty(queryParams)) {
    redirectToHome();
  } else if (isVideoModalOpen) {
    dispatch(closeVideoModal());
    analytics.track(
      'navigation',
      'Clicked back button on video modal chat info tab'
    );
    logger.info(
      'Clicked back button on video modal chat info tab',
      'navigation.coachChatBackButtonHandler'
    );
  } else if (has(queryParams, 'view')) {
    dispatch(infoTabAction(null));
    let param = queryParams;
    delete param.view;
    param = stringify(param);
    history.replace({
      search: param
    });

    analytics.track('navigation', 'Clicked back button on chat info tab');
    logger.info(
      'Clicked back button on chat info tab',
      'navigation.coachChatBackButtonHandler'
    );
  } else if (isMobile) {
    dispatch(infoTabAction(null));
    history.replace({
      search: ''
    });

    analytics.track('navigation', 'Clicked back button on chat center tab');
    logger.info(
      'Clicked back button on chat center tab',
      'navigation.coachChatBackButtonHandler'
    );
    dispatch(currentUserAction(null));
  }
};

export const backButtonHandlersMap = {
  coach: coachChatBackButtonHandler
};

export const handleRootPathBackPress = (pathName) => {
  if (has(backButtonHandlersMap, pathName)) {
    backButtonHandlersMap[pathName]();
  } else {
    redirectToHome();
  }
};

export const openNewWindow = (url, target = '_blank') => {
  if (isEmpty(url)) {
    logger.error('Empty url received', 'navigation.openNewWindow');
  }
  analytics.screen('app', 'redirect', { externalUrl: url });
  window.open(url, target);
};
