/**
 * Config options for our app.
 * Defined in .env file
 * use .env.development.local to override these
 */
// TODO fix this dependency cycle issue
// eslint-disable-next-line import/no-cycle
import { isNativePlatform } from '../utils/capacitor';

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID_WEB,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_RESPONSE_TYPE,
  REACT_APP_AUTH0_CLIENT_ID_MOBILE,
  REACT_APP_AUTH0_SCOPE,
  REACT_APP_DEEPLINK_URL,
  REACT_APP_BRAZE_ENABLED,
  REACT_APP_BRAZE_API_KEY,
  REACT_APP_BRAZE_SDK_ENDPOINT,
  REACT_APP_IDLE_SESSION_TIMEOUT,
  REACT_APP_API_URL,
  REACT_APP_API_TIMEOUT,
  REACT_APP_LOGDNA_KEY,
  REACT_APP_ENVIRONMENT,
  REACT_APP_ANALYTICS_KEY,
  REACT_APP_ANALYTICS_API_URL,
  REACT_APP_ANALYTICS_ENABLED,
  REACT_APP_ANALYTICS_TAGS,
  REACT_APP_HOME_PAGE_VIDEO_URL,
  REACT_APP_HOME_PAGE_SHOW_KALTURA_VIDEO,
  REACT_APP_HOME_PAGE_VIDEO_TRANSCRIPT_URL,
  REACT_APP_BRAND,
  REACT_APP_BRANCH_IO_KEY,
  REACT_APP_APP_STORE_LINK,
  REACT_APP_PLAY_STORE_LINK,
  REACT_APP_WEB_URL,
  REACT_APP_REQUEST_API_URL,
  REACT_APP_PARTNER_API_URL,
  REACT_APP_NOTIFICATION_API_URL,
  REACT_APP_NOTIFICATION_API_KEY,
  REACT_APP_USER_API_URL,
  REACT_APP_CONTENT_API_URL,
  REACT_APP_CDN_BASE_URL,
  REACT_APP_CONTENT_VERSION_MAP,
  REACT_APP_LOGDNA_APP_NAME,
  REACT_APP_ANALYTICS_SESSION_TIMEOUT,
  REACT_APP_REQUEST_MANAGEMENT_SOURCE,
  REACT_APP_SHOW_PRIVACY_INFO,
  REACT_APP_SHOW_IN_APP_POLICY,
  REACT_APP_SHOW_POWERED_BY_LOGO,
  REACT_APP_ASSESSMENT_VERSION_MAP,
  REACT_APP_PARTNER_KEY,
  REACT_APP_DEBUG_LOGS,
  REACT_APP_DEFAULT_LOGO_PATH,
  REACT_APP_PRIMARY_LOGO_PATH,
  REACT_APP_SECONDARY_LOGO_PATH,
  REACT_APP_SUPPORT_EMAIL,
  REACT_APP_APP_NAME,
  REACT_APP_FALLBACK_LANG,
  REACT_APP_AUTH_TOKEN_INSPECTION_INTERVAL,
  REACT_APP_AUTH_TOKEN_TIMEOUT_DURATION,
  REACT_APP_REFRESH_TOKEN_REUSE_INTERVAL,
  REACT_APP_REFRESH_TOKEN_LIFETIME_WEB,
  REACT_APP_REFRESH_TOKEN_LIFETIME_MOBILE,
  REACT_APP_COMPANY_LIST_TTL,
  REACT_APP_IAM_TOKEN_LIFETIME,
  NODE_ENV,
  REACT_APP_APP_ID,
  REACT_APP_RETAKE_ASSESSMENT_FOR_ELIGIBILITY_DURATION,
  REACT_APP_BUILD_VER,
  REACT_APP_PROGRAM_NAME,
  REACT_APP_ACCOUNT_DELETION_ENABLED,
  REACT_APP_FALLBACK_EMERGENCY_CONTACT,
  REACT_APP_PRIVACY_POLICY_URL,
  REACT_APP_TERMS_OF_USE_URL,
  REACT_APP_FORCE_UPDATE_ENABLED,
  REACT_APP_FORCE_UPDATE_CACHE_EXPIRY,
  REACT_APP_CARE_PLAN_EXPIRY_TIME,
  REACT_APP_CONTENT_API_KEY,
  REACT_APP_GRAPHQL_API_URL,
  REACT_APP_ELIGIBILITY_COMPANY_NAMES_MAP,
  REACT_APP_USER_API_KEY,
  REACT_APP_CDN_CONTENT_PATH,
  REACT_APP_CDN_BRAND_CONTENT_PATH,
  REACT_APP_CDN_HYGRAPH_PATH,
  REACT_APP_CONVERSATION_SERVICE_URL,
  REACT_APP_RETAKE_ASSESSMENT_DURATION_MAP,
  REACT_APP_FIRST_LOGIN_POPUP_ENABLED
} = process.env;
export default {
  appId: REACT_APP_APP_ID,
  auth0: {
    domain: REACT_APP_AUTH0_DOMAIN,
    clientID: isNativePlatform
      ? REACT_APP_AUTH0_CLIENT_ID_MOBILE
      : REACT_APP_AUTH0_CLIENT_ID_WEB,
    // Callback url is based on the current url so we calculate it dynamically
    redirectUri: isNativePlatform
      ? `${REACT_APP_DEEPLINK_URL}:///callback`
      : `${window.location.origin}/callback`,
    audience: REACT_APP_AUTH0_AUDIENCE,
    responseType: REACT_APP_AUTH0_RESPONSE_TYPE,
    scope: JSON.parse(REACT_APP_AUTH0_SCOPE).join(' ')
  },
  deeplinkUrl: REACT_APP_DEEPLINK_URL,
  idleSessionTimeout: REACT_APP_IDLE_SESSION_TIMEOUT,
  tokenInspectionInterval: REACT_APP_AUTH_TOKEN_INSPECTION_INTERVAL,
  tokenTimeoutDuration: REACT_APP_AUTH_TOKEN_TIMEOUT_DURATION,
  refreshTokenReuseInterval: REACT_APP_REFRESH_TOKEN_REUSE_INTERVAL,
  refreshTokenLifeTime: isNativePlatform
    ? REACT_APP_REFRESH_TOKEN_LIFETIME_MOBILE
    : REACT_APP_REFRESH_TOKEN_LIFETIME_WEB,
  companyListTtl: REACT_APP_COMPANY_LIST_TTL,
  iamTokenLifetime: REACT_APP_IAM_TOKEN_LIFETIME,
  api: {
    url: REACT_APP_API_URL,
    timeout: REACT_APP_API_TIMEOUT,
    requestUrl: REACT_APP_REQUEST_API_URL
  },
  partner: {
    url: REACT_APP_PARTNER_API_URL,
    key: REACT_APP_PARTNER_KEY
  },
  conversation: {
    url: REACT_APP_CONVERSATION_SERVICE_URL
  },
  logDna: {
    key: REACT_APP_LOGDNA_KEY,
    appName: REACT_APP_LOGDNA_APP_NAME
  },
  environment: REACT_APP_ENVIRONMENT,
  analytics: {
    key: REACT_APP_ANALYTICS_KEY,
    url: REACT_APP_ANALYTICS_API_URL,
    enabled: REACT_APP_ANALYTICS_ENABLED,
    tags: REACT_APP_ANALYTICS_TAGS.split(',')
  },
  videoUrl: REACT_APP_HOME_PAGE_VIDEO_URL,
  brand: REACT_APP_BRAND,
  branchIo: {
    key: REACT_APP_BRANCH_IO_KEY,
    link: {
      playStore: REACT_APP_PLAY_STORE_LINK,
      appStore: REACT_APP_APP_STORE_LINK
    }
  },
  webUrl: REACT_APP_WEB_URL,
  showKalturaVideo: REACT_APP_HOME_PAGE_SHOW_KALTURA_VIDEO,
  videoTranscriptUrl: REACT_APP_HOME_PAGE_VIDEO_TRANSCRIPT_URL,
  sessionTimeout: REACT_APP_ANALYTICS_SESSION_TIMEOUT,
  requestManagementSource: REACT_APP_REQUEST_MANAGEMENT_SOURCE,
  showPrivacyInfo: REACT_APP_SHOW_PRIVACY_INFO,
  showPoweredByLogo: REACT_APP_SHOW_POWERED_BY_LOGO,
  assessmentVersionMap: REACT_APP_ASSESSMENT_VERSION_MAP,
  graphql: {
    url: REACT_APP_GRAPHQL_API_URL
  },
  user: {
    url: REACT_APP_USER_API_URL,
    apiKey: REACT_APP_USER_API_KEY
  },
  notification: {
    url: REACT_APP_NOTIFICATION_API_URL,
    apiKey: REACT_APP_NOTIFICATION_API_KEY
  },
  content: {
    url: REACT_APP_CONTENT_API_URL,
    key: REACT_APP_CONTENT_API_KEY
  },
  cdnBaseUrl: REACT_APP_CDN_BASE_URL,
  cdnContentUrl: `${REACT_APP_CDN_BASE_URL}${REACT_APP_CDN_CONTENT_PATH}`,
  cdnBrandContentUrl: `${REACT_APP_CDN_BASE_URL}${REACT_APP_CDN_BRAND_CONTENT_PATH}`,
  cdnHygraphUrl: `${REACT_APP_CDN_BASE_URL}${REACT_APP_CDN_HYGRAPH_PATH}`,
  contentVersionMap: REACT_APP_CONTENT_VERSION_MAP,
  defaultLogoPath: REACT_APP_DEFAULT_LOGO_PATH,
  primaryLogoPath: REACT_APP_PRIMARY_LOGO_PATH,
  secondaryLogoPath: REACT_APP_SECONDARY_LOGO_PATH,
  supportEmail: REACT_APP_SUPPORT_EMAIL,
  appName: REACT_APP_APP_NAME,
  fallbackLang: REACT_APP_FALLBACK_LANG,
  debugLogs: REACT_APP_DEBUG_LOGS,
  nodeEnvironment: NODE_ENV,
  retakeAssessmentForEligibilityDuration:
    REACT_APP_RETAKE_ASSESSMENT_FOR_ELIGIBILITY_DURATION,
  braze: {
    enabled: REACT_APP_BRAZE_ENABLED,
    apiKey: REACT_APP_BRAZE_API_KEY,
    endpoint: REACT_APP_BRAZE_SDK_ENDPOINT
  },
  privacyPolicyUrl: REACT_APP_PRIVACY_POLICY_URL,
  termsOfUseUrl: REACT_APP_TERMS_OF_USE_URL,
  appVersion: REACT_APP_BUILD_VER,
  programName: REACT_APP_PROGRAM_NAME,
  accountDeletionEnabled: REACT_APP_ACCOUNT_DELETION_ENABLED,
  fallbackEmergencyContact: REACT_APP_FALLBACK_EMERGENCY_CONTACT,
  forceUpdateEnabled: REACT_APP_FORCE_UPDATE_ENABLED,
  forceUpdateCacheExpiry: REACT_APP_FORCE_UPDATE_CACHE_EXPIRY,
  carePlanExpiry: REACT_APP_CARE_PLAN_EXPIRY_TIME,
  showInAppPolicy: REACT_APP_SHOW_IN_APP_POLICY,
  eligibilityCompanyNamesMap: REACT_APP_ELIGIBILITY_COMPANY_NAMES_MAP,
  retakeAssessmentDurationMap: REACT_APP_RETAKE_ASSESSMENT_DURATION_MAP,
  firstLoginPopupEnabled: REACT_APP_FIRST_LOGIN_POPUP_ENABLED
};
