import { isPlatform } from '@ionic/react';
import { android, ios, formfactor, mouse, browser } from 'platform-detect';
import platformType from 'assets/data/platform-types';

export const platformInfo = {
  isDesktop:
    !(android || ios) && (formfactor === platformType.DESKTOP || mouse),
  isTab:
    formfactor === platformType.TABLET &&
    (isPlatform(platformType.CAPACITOR) || ios || android),
  isMobile:
    formfactor === platformType.PHONE &&
    (isPlatform(platformType.CAPACITOR) || ios || android) &&
    formfactor !== platformType.TABLET,
  isIos: ios,
  isBrowser: browser,
  isAndroid: android,
  isMobileWeb:
    (formfactor === platformType.PHONE || formfactor === platformType.TABLET) &&
    !isPlatform(platformType.CAPACITOR) &&
    (ios || android)
};
