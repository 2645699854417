import {
  SHOW_MODAL,
  CLOSE_MODAL,
  TOGGLE_MODAL_HIDE_BUTTON
} from '../actions/types';

const INITIAL_STATE = {
  isOpen: false,
  component: null,
  maxWidth: 522,
  isScrollable: false,
  modalHeader: null,
  showFullScreenInMobile: false,
  hideCloseButton: false,
  source: null,
  showModalInCenter: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_MODAL: {
      const {
        component,
        maxWidth,
        maxHeight,
        onClose,
        hideCloseButton,
        isScrollable,
        modalHeader,
        showFullScreenInMobile,
        props,
        source,
        showModalInCenter
      } = action.payload;
      return {
        isOpen: true,
        component,
        maxWidth: maxWidth || 522,
        maxHeight,
        onClose,
        isScrollable,
        modalHeader,
        showFullScreenInMobile,
        props,
        hideCloseButton,
        source,
        showModalInCenter
      };
    }
    case TOGGLE_MODAL_HIDE_BUTTON:
      return {
        ...state,
        hideCloseButton: !state.hideCloseButton
      };
    case CLOSE_MODAL:
      return INITIAL_STATE;
    default:
      return state;
  }
};
