/**
Reducer for managing the state when fetching recent activities
*/

import {
  GET_RECENT_ACTIVITIES_DONE,
  GET_SESSION_ACTIVITIES_DONE,
  GET_RECENT_ACTIVITIES_ERROR,
  GET_RECENT_ACTIVITY_FILTERS_DONE,
  GET_RECENT_ACTIVITIES
} from '../actions/types';

const INITIAL_STATE = {
  recentActivities: [],
  sessionActivities: [],
  recentActivityFilters: [],
  isProcessing: false,
  hasError: false
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_RECENT_ACTIVITIES_DONE:
      return {
        ...state,
        ...action.data,
        hasError: false,
        isProcessing: false
      };
    case GET_SESSION_ACTIVITIES_DONE:
      return {
        ...state,
        ...action.data
      };
    case GET_RECENT_ACTIVITIES:
      return {
        ...state,
        hasError: false,
        isProcessing: true
      };
    case GET_RECENT_ACTIVITIES_ERROR:
      return {
        ...state,
        hasError: true,
        isProcessing: false
      };
    case GET_RECENT_ACTIVITY_FILTERS_DONE:
      return {
        ...state,
        ...action.data,
        hasError: false,
        isProcessing: false
      };
    default:
      return state;
  }
};
