/**
Reducer for managing the state when creating the care plan report
*/

import {
  GET_CARE_PLAN,
  GET_CARE_PLAN_DONE,
  GET_CARE_PLAN_ERROR
} from '../actions/types';

const INITIAL_STATE = {
  cards: [],
  isProcessing: false,
  hasError: false
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CARE_PLAN_DONE:
      return {
        ...state,
        ...action.data,
        hasError: false,
        isProcessing: false
      };
    case GET_CARE_PLAN:
      return {
        ...state,
        isProcessing: true,
        hasError: false
      };
    case GET_CARE_PLAN_ERROR:
      return {
        ...state,
        isProcessing: false,
        hasError: true
      };
    default:
      return state;
  }
};
