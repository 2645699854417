// utils
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import logger from 'utils/logger';
import { getPlatform } from 'utils/capacitor';
import { openNewWindow } from 'utils/navigation';
// config
import config from '../../config';

const {
  branchIo: {
    link: { playStore, appStore }
  }
} = config;

const deviceBasedStoreUrl = {
  ios: {
    url: appStore
  },
  android: {
    url: playStore
  },
  // for unit test
  web: {
    url: ''
  }
};

export const redirectToStore = () => {
  const source = 'app-force-update';
  analytics.track(eventCategories.MODAL, 'clicked update app button', {
    source,
    trigger: 'modal_action'
  });
  logger.info(
    'Clicked update app button',
    'AppForceUpdate.utils.redirectToStore',
    { source }
  );
  const platform = getPlatform();
  const { url } = deviceBasedStoreUrl[platform];
  openNewWindow(url);
};
