/**
 * Reducer to manage the state of currently active avatar
 */

import {
  GET_APPSYNC_URL_DONE,
  GET_APPSYNC_URL_ERROR,
  GET_APPSYNC_URL_START
} from '../actions/types';

const INITIAL_STATE = {
  hasError: false,
  isLoading: false,
  url: null,
  region: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_APPSYNC_URL_START:
      return {
        ...state,
        isLoading: true,
        hasError: false
      };
    case GET_APPSYNC_URL_DONE:
      return {
        ...state,
        url: action.url,
        region: action.region,
        isLoading: false
      };
    case GET_APPSYNC_URL_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false
      };
    default:
      return state;
  }
};
