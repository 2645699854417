/**
 * main index file that renders the app
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { SplashScreen } from '@capacitor/splash-screen';
import { handleSessionOnIdleState } from './App.utils';
import { register } from './register-service-worker';

const initAndRenderApp = async () => {
  try {
    // Only to be used in development to check for performance issues
    // Uncomment while debugging performance issues
    // if (process.env.NODE_ENV === 'development') {
    //   const whyDidYouRender = require('@welldone-software/why-did-you-render');
    //   whyDidYouRender(React, {
    //     trackAllPureComponents: true,
    //   });
    // }

    // Registering the service worker in order to listen to in app message from braze
    // for the feedback feature. If the cache is persisted after version upgrade user
    // will have to hard reload and the new version will be available

    await register();

    const { initNativeSecretKey } = await import('./init/native-secret-key');
    await initNativeSecretKey();
    await Promise.all([
      import('./init/appboy'),
      import('./init/capacitor'),
      import('./init/auth'), // only used for web user for refresh or url change on web.
      import('./init/logout'), // only used for native application users to logout them on app killing.
      import('./init/polyfills'),
      import('./init/globalStyles')
    ]);
    try {
      await SplashScreen.hide();
      const { default: logger } = await import('./utils/logger');
      logger.info('Splash screen is hidden', 'index.jsx');
      await handleSessionOnIdleState();
    } catch (error) {
      const { default: logger } = await import('./utils/logger');
      logger.error('Unable to hide splash screen', 'index.jsx', { error });
    }
  } catch (error) {
    const { default: logger } = await import('./utils/logger');
    logger.error('Error while initializing app', 'index.jsx', { error });
  } finally {
    const { default: App } = await import('./App');
    const rootElement = document.getElementById('root');
    const root = createRoot(rootElement);
    root.render(<App />);
  }
};

initAndRenderApp();
