/**
 * Reducer to manage the state of anonymous banner
 */

import { TOGGLE_ANONYMOUS_ALERT } from '../../actions/types';

const INITIAL_STATE = false;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_ANONYMOUS_ALERT:
      return action.payload;
    default:
      return state;
  }
};
