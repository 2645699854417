/* eslint-disable import/no-cycle */
import config from '../config';

import { initializeApi, httpMethod } from './index';

const USER_API_BASE_URL = config.user.url;

const fetchFromApi = initializeApi(USER_API_BASE_URL);

// Api class that contains all our user api endpoints
class UserAPI {
  updateActivity = (payload) =>
    fetchFromApi({
      endpoint: 'activity',
      method: httpMethod.PATCH,
      data: payload
    });

  getActivity = () =>
    fetchFromApi({
      endpoint: 'activity',
      method: httpMethod.GET
    });

  updatePassword = (payload) =>
    fetchFromApi({
      endpoint: 'password',
      method: httpMethod.PUT,
      data: payload
    });

  deleteAccount = () =>
    fetchFromApi({
      endpoint: 'users',
      method: httpMethod.DELETE,
      retryRequired: true
    });

  getAppSettings = () =>
    fetchFromApi({
      endpoint: 'settings',
      method: httpMethod.GET,
      retryRequired: true
    });

  updateAppSettings = (payload) =>
    fetchFromApi({
      endpoint: 'settings',
      method: httpMethod.PATCH,
      data: payload
    });

  updateDevices = () =>
    fetchFromApi({
      endpoint: 'devices',
      method: httpMethod.PATCH
    });

  getCoaches = ({ queryKey }) => {
    const [, queryParams] = queryKey;
    return fetchFromApi({
      endpoint: 'coaches',
      method: httpMethod.GET,
      params: queryParams
    });
  };

  updateCoach = (payload) =>
    fetchFromApi({
      endpoint: 'coach',
      method: httpMethod.PATCH,
      data: payload
    });

  addCoach = (payload) =>
    fetchFromApi({
      endpoint: 'coach',
      method: httpMethod.POST,
      data: payload
    });

  getAnonymousToken = ({ companyName, locale, tenant }) =>
    fetchFromApi({
      endpoint: 'user/token/anonymous',
      method: httpMethod.GET,
      params: {
        company_name: companyName,
        locale: locale || 'en-US',
        tenant
      },
      apiKey: config.user.apiKey,
      authRequired: false
    });

  updateUserDetails = (payload) =>
    fetchFromApi({
      endpoint: 'users',
      method: httpMethod.PATCH,
      data: payload
    });
}

export default new UserAPI();
