import surveyTypes from 'assets/data/feedbackSurvey/surveyTypes';
import { get } from 'lodash';
import {
  GET_FEEDBACK_DETAILS_START,
  SET_FEEDBACK_DETAILS_DONE,
  SET_FEEDBACK_DETAILS_ERROR,
  SET_LAST_FEEDBACK_TIMESTAMP
} from '../actions/types';

const INITIAL_STATE = {
  [surveyTypes.coachRating]: {}
};

export default (state = INITIAL_STATE, action) => {
  const feedbackType = get(action, 'payload.feedbackType', 'default');
  switch (action.type) {
    case GET_FEEDBACK_DETAILS_START:
      return {
        ...state,
        [surveyTypes[feedbackType]]: {
          isProcessing: true,
          hasError: false
        }
      };
    case SET_FEEDBACK_DETAILS_DONE:
      return {
        ...state,
        [surveyTypes[feedbackType]]: {
          isProcessing: false,
          hasError: false,
          ...get(action, 'payload.response', {})
        }
      };
    case SET_FEEDBACK_DETAILS_ERROR:
      return {
        ...state,
        [surveyTypes[feedbackType]]: {
          isProcessing: false,
          hasError: true
        }
      };
    case SET_LAST_FEEDBACK_TIMESTAMP:
      return {
        ...state,
        [surveyTypes[feedbackType]]: {
          ...state[[surveyTypes[feedbackType]]],
          lastRatedTimestamp: get(action, 'payload.currentTimestamp')
        }
      };
    default:
      return state;
  }
};
