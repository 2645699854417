import { App } from '@capacitor/app';
import analytics from 'utils/analytics';
import { debounce, isEmpty, get } from 'lodash';
import auth, { isLoggedIn } from 'utils/auth';
import { PushNotifications } from '@capacitor/push-notifications';
import eventCategories from 'utils/analytics/categories';
import helpers from 'utils/helpers';
import history from 'utils/history';
import { isNativePlatform, getDeviceId } from 'utils/capacitor';
import logger from 'utils/logger';
import notification from 'api/notification';
import { handleRootPathBackPress } from 'utils/navigation';
import store, { dispatch } from 'store';
import { showModal, closeModal } from 'store/actions/ModalAction';
import { closeVideoModal } from 'store/actions/media/VideoPlayerModalAction';
import BlockAccess from 'components/Popups/BlockAccess';
import AppForceUpdate from 'components/AppForceUpdate';
import i18n from 'assets/lang';
import logoutReasons from 'utils/auth/logoutReasons';
import userApi from './api/user';
import partnerApi from './api/partner';
import getVideoFullScreenDetails from './containers/SelfUse/components/Session/VideoScreen/videoScreen.utils';
import { lockScreenOrientation } from './containers/SelfUse/components/Session/VideoScreen/lockOrientation';
import { videoFullScreenInactive } from './store/actions/VideoFullScreenAction';
import config from './config';
import storage from './utils/storage';
import { getParsedValue } from './utils/storage/helpers';

const { forceUpdateCacheExpiry, appVersion, idleSessionTimeout } = config;

let clickCount = 0;
let timeout = null;
let blockHardwareBackButton = false;
const LAST_ACTIVE_TIME = 'lastActiveTime';

const showToast = ({ present }) => {
  present({
    message: i18n.t('exitAppToastMessage'),
    translucent: true
  });
};

const handleBackButtonDoubleTap = ({ present, dismiss }) => {
  clickCount += 1;
  if (clickCount === 2) {
    if (timeout) clearTimeout(timeout);
    clickCount = 0;
    dismiss();
    App.exitApp();
  } else {
    // 1 second delay to detect the double tap of hardware back button
    showToast({ present });
    timeout = setTimeout(() => {
      clickCount = 0;
      dismiss();
    }, 1000);
  }
};

export const hardwareBackButtonHandler = ({ present, dismiss }) => {
  document.addEventListener('ionBackButton', (event) => {
    event.detail.register(1, () => {
      const canGoBack = history.length > 1;
      const { exitFullScreen } = getVideoFullScreenDetails();
      const { videoMode } = store.getState();
      const { isFullScreen } = videoMode;
      analytics.track(eventCategories.APP, 'clicked on device back button');

      if (blockHardwareBackButton) {
        logger.info(
          'Blocked hardware back button action by user, rooted device detected.',
          'App.utils.hardwareBackButtonHandler'
        );
        return;
      }

      dispatch(closeModal());
      dispatch(closeVideoModal());
      if (isFullScreen && exitFullScreen) {
        lockScreenOrientation('portrait');
        exitFullScreen();
        dispatch(videoFullScreenInactive());
      } else if (!canGoBack) {
        handleBackButtonDoubleTap({
          present,
          dismiss
        });
      } else {
        const currentPath = helpers.getActivePath();
        if (currentPath) {
          const pathName = currentPath.split('/')[1];
          const homeUrl = helpers.homeUrl();
          if (helpers.isActivePath(homeUrl.split('/')[1])) {
            // Detect double click on hardware button and exit the app
            handleBackButtonDoubleTap({ present, dismiss });
          } else if (helpers.isRootPath()) {
            // handles back button pressed on root path and/or query params on root path
            handleRootPathBackPress(pathName);
          } else {
            helpers.goBack();
          }
        } else {
          helpers.goBack();
        }
      }
    });
  });
};

export const trackActivity = debounce(() => {
  if (isLoggedIn()) {
    userApi.updateActivity({ type: 'active' });
  }
}, 2000);

export const resetBadgeCount = () => {
  PushNotifications.removeAllDeliveredNotifications();
  const deviceId = getDeviceId();
  if (!isEmpty(deviceId)) {
    notification.updateBadgeCount({ badgeCount: 0, deviceId: getDeviceId() });
  } else {
    logger.warn(
      'deviceId was empty while trying to reset badge count',
      'App.utils.js'
    );
  }
};

export const rootedDeviceHandler = (setIsRootedDevice) => {
  if (isNativePlatform && window.cordova) {
    window.cordova.exec(
      (isRooted) => {
        setIsRootedDevice(isRooted);
        if (isRooted) {
          blockHardwareBackButton = true;
          logger.info(
            'Rooted/Jailbroken device is detected.',
            'App.utils.rootedDeviceHandler'
          );
          analytics.info(
            eventCategories.APP,
            'Rooted/Jailbroken device is detected.'
          );
          dispatch(showModal(BlockAccess, { hideCloseButton: true }));
        } else {
          blockHardwareBackButton = false;
          logger.info(
            'Device is not Rooted/Jailbroken.',
            'App.utils.rootedDeviceHandler'
          );
        }
      },
      (error) => {
        logger.error(
          'An error occurred while determining the root access status',
          'App.utils.rootedDeviceHandler',
          { error }
        );
      },
      'IRoot',
      'isRooted',
      []
    );
  }
};
export const handleSessionOnIdleState = async () => {
  if (!isLoggedIn()) {
    return;
  }
  const lastActiveTime = await storage.getItem(LAST_ACTIVE_TIME);
  logger.info(
    'Timeout: checking for idle session timeout',
    'App.utils.handleSessionOnIdleState',
    { lastActiveTime }
  );
  if (!isEmpty(lastActiveTime)) {
    const currentTimestamp = Date.now();
    const timeDiffInMillisecond = currentTimestamp - lastActiveTime;
    logger.info(
      'Timeout: Found last active time for user',
      'App.utils.handleSessionOnIdleState',
      {
        lastActiveTime: new Date(lastActiveTime),
        currentTimestamp: new Date(currentTimestamp),
        timeDiffInMillisecond,
        idleSessionTimeout
      }
    );
    if (timeDiffInMillisecond >= idleSessionTimeout) {
      logger.info(
        'Timeout: logging out as expiry reached',
        'App.utils.handleSessionOnIdleState',
        {
          currentTimestamp: new Date(currentTimestamp),
          lastActiveTime: new Date(lastActiveTime),
          timeDiffInMillisecond,
          idleSessionTimeout
        }
      );
      analytics.info(eventCategories.APP, 'App logout due to session expire');
      auth.logout(logoutReasons.expired);
    }
  }
};
export const forceUpdateHandler = async () => {
  try {
    // Fetch force update app details from storage
    let forceUpdateAppDetails = getParsedValue(
      await storage.getItem('forceUpdateAppDetails')
    );
    const currentTimestamp = Math.floor(Date.now() / 1000);

    if (
      isEmpty(forceUpdateAppDetails) ||
      currentTimestamp - forceUpdateAppDetails.timestamp >
        forceUpdateCacheExpiry
    ) {
      // Fetch force update app details from api
      const forceUpdateAppStatus = await partnerApi.getForceUpdateAppStatus();
      const status = get(
        forceUpdateAppStatus,
        'data.isAppUpdateRequired',
        false
      );
      forceUpdateAppDetails = {
        status,
        timestamp: Math.floor(Date.now() / 1000),
        appVersion
      };
      await storage.setItem('forceUpdateAppDetails', forceUpdateAppDetails);
    }

    const isAppUpdateRequired =
      forceUpdateAppDetails.status &&
      forceUpdateAppDetails.appVersion === appVersion;
    if (isAppUpdateRequired) {
      dispatch(
        showModal(AppForceUpdate, {
          hideCloseButton: true,
          source: 'app-force-update'
        })
      );
    }
    blockHardwareBackButton = isAppUpdateRequired;
  } catch (error) {
    logger.error(
      'An error occurred while checking force update app status',
      'App.utils.forceUpdateHandler'
    );
  }
};
