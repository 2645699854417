import config from '../config';
import auth from '../utils/auth';
import { initializeApi, httpMethod } from './index';

const {
  partner: { url, key: apiKey },
  brand,
  appVersion
} = config;

const fetchFromApi = initializeApi(url);

// Api class that contains all our request api endpoints
class PartnerApi {
  getCompanyList = () =>
    fetchFromApi({
      endpoint: `tenant/${brand}/connection`,
      method: httpMethod.GET,
      authRequired: false,
      retryRequired: true,
      apiKey
    });

  getCompanyDetails = async (companyName) => {
    const { locale } = await auth.getUserDetails();
    return fetchFromApi({
      endpoint: `company?company_name=${encodeURIComponent(
        companyName
      )}&tenant=${brand}&locale=${encodeURIComponent(locale)}`,
      method: httpMethod.GET,
      retryRequired: true
    });
  };

  getCompanyDetailsByPlanSponsorId = async (planSponsorId) =>
    fetchFromApi({
      endpoint: `company?plan_sponsor_id=${encodeURIComponent(
        planSponsorId
      )}&tenant=${brand}`,
      method: httpMethod.GET,
      retryRequired: true
    });

  getRegistrationFields = (companyName) =>
    fetchFromApi({
      endpoint: `registration-fields?company_name=${companyName}&tenant=${brand}`,
      method: httpMethod.GET,
      authRequired: false,
      retryRequired: true,
      apiKey
    });

  getForceUpdateAppStatus = () =>
    fetchFromApi({
      endpoint: `tenant/${brand}?currentAppVersion=${appVersion}`,
      method: httpMethod.GET,
      authRequired: false,
      apiKey,
      retryRequired: true
    });
}

export default new PartnerApi();
