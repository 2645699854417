import {
  EXECUTE_SAVE_EVENT_START,
  EXECUTE_SAVE_EVENT_DONE
} from '../actions/types';

const INITIAL_STATE = {
  isSavingEvent: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EXECUTE_SAVE_EVENT_START: {
      return {
        isSavingEvent: true
      };
    }
    case EXECUTE_SAVE_EVENT_DONE: {
      return {
        ...state,
        isSavingEvent: false
      };
    }
    default:
      return state;
  }
};
