import {
  SET_SKIP_DIV_REF,
  SET_SKIP_TO_MAIN_CONTENT_REF
} from '../actions/types';

const INITIAL_STATE = {
  skipDivRef: null,
  skipToMainContentRef: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SKIP_DIV_REF:
      return {
        ...state,
        skipDivRef: action.payload
      };
    case SET_SKIP_TO_MAIN_CONTENT_REF:
      return {
        ...state,
        skipToMainContentRef: action.payload
      };
    default:
      return state;
  }
};
