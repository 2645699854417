import CryptoJS from 'crypto-js';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { isEmpty } from 'lodash';
import retry from 'async-retry';
// eslint-disable-next-line import/no-cycle
import logger from '../logger';
import { bailIfErrorNotRetryable, getRetryConfig } from './helpers';

const generateNewSecretKey = (password) => {
  // 512 bits random password to generate secret key
  const salt = CryptoJS.lib.WordArray.random(16);
  const key = CryptoJS.PBKDF2(password, salt, {
    keySize: 8, // 256 bits
    iterations: 10000, // NIST recommended minimum
    hasher: CryptoJS.algo.SHA256 // NIST recommended PBKDF2 hash algo
  }).toString();
  if (!isEmpty(key)) {
    logger.info(
      'generated new secret key successfully',
      'encryption-key.generateNewSecretKey'
    );
    return key;
  }
  throw new Error('error in generating new secret key');
};

export const getMD5Email = (email) => CryptoJS.MD5(email).toString();

const SECRET_KEY_NAME = 'wayforward_secret_key';

const getStoredSecretKey = async () => {
  try {
    const { value } = await SecureStoragePlugin.get({
      key: SECRET_KEY_NAME
    });
    if (isEmpty(value)) {
      logger.error(
        'fetched secret key is empty',
        'encryption-key.getStoredSecretKey'
      );
      throw new Error('Fetched secret key is empty');
    }
    return value;
  } catch (error) {
    logger.warn(
      'Warning in fetching secret key from storage',
      'encryption-key.getStoredSecretKey',
      { errName: error.name, error }
    );
    throw new Error('Error in fetching secret key from storage');
  }
};

export const getStoredSecretKeyWithRetry = async () => {
  try {
    const value = await retry(
      (bail) => getStoredSecretKey().catch(bailIfErrorNotRetryable(bail)),
      getRetryConfig((err) =>
        logger.info(
          'Retrying to get stored secret key.',
          'encryption-key.getStoredSecretKeyWithRetry',
          {
            err
          }
        )
      )
    );
    return value;
  } catch (error) {
    logger.error(
      'Not able to get secret key after three retries',
      'encryption-key.getStoredSecretKeyWithRetry',
      { errName: error.name, error }
    );
    throw error;
  }
};

const checkSecretKey = async () => {
  try {
    await getStoredSecretKey();

    logger.info(
      'secret key is found in secure storage',
      'encryption-key.checkSecretKey'
    );
    return true;
  } catch (error) {
    logger.error(
      'error in checking secret key',
      'encryption-keys.checkSecretKey',
      { errName: error.name, error }
    );
    return false;
  }
};

const storeSecretKey = async (secretKey) => {
  try {
    await SecureStoragePlugin.set({
      key: SECRET_KEY_NAME,
      value: secretKey
    });
    logger.info(
      'Key and value stored in secure storage ',
      'encryption-key.storeSecretKey',
      { key: SECRET_KEY_NAME }
    );
  } catch (error) {
    logger.error(
      'error in storing secret key',
      'encryption-key.storeSecretKey',
      { errName: error.name, error }
    );
    throw new Error('Error in storing secret key');
  }
};

const storeSecretKeywithRetry = async (secretKey) => {
  try {
    const value = await retry(
      (bail) => storeSecretKey(secretKey).catch(bailIfErrorNotRetryable(bail)),
      getRetryConfig((err) =>
        logger.info(
          'Retrying to store secret key.',
          'encryption-key.storeSecretKeywithRetry',
          {
            err
          }
        )
      )
    );
    logger.info(
      'successfully stored secret key',
      'encryption-key.storeSecretKeywithRetry'
    );
    return value;
  } catch (error) {
    logger.error(
      'Not able to store secret key after three retries',
      'encryption-key.storeSecretKeywithRetry',
      { errName: error.name, error }
    );
    throw error;
  }
};

const generateAndStoreSecretKey = async () => {
  try {
    const password = CryptoJS.lib.WordArray.random(16);
    const secretKey = generateNewSecretKey(password);

    logger.info(
      'Successfully generated secret key',
      'encryption-key.generateAndStoreSecretKey'
    );

    await storeSecretKeywithRetry(secretKey);

    logger.info(
      'Successfully stored secret key',
      'encryption-key.generateAndStoreSecretKey'
    );

    return secretKey;
  } catch (error) {
    logger.error(
      'Error in generating and storing secret key',
      'encryption-key.generateAndStoreSecretKey',
      { errName: error.name, error }
    );
    throw error;
  }
};

export const initializeSecretKey = async () => {
  try {
    const isKeyPresent = await checkSecretKey();
    if (isKeyPresent) {
      logger.info(
        'secret key is present',
        'encryption-key.initializeSecretKey'
      );
      return;
    }
    logger.info(
      'Secret key not available in secure storage, generating new secret key',
      'encryption-key.initializeSecretKey',
      { SECRET_KEY_NAME }
    );

    await generateAndStoreSecretKey();
  } catch (error) {
    logger.error(
      'Error in initializeSecretKey',
      'encryption-key.initializeSecretKey',
      {
        error,
        key: SECRET_KEY_NAME
      }
    );
    throw error;
  }
};
