import {
  TOGGLE_REPORT_ACCORDION,
  SET_DEFAULT_REPORT_ACCORDION
} from '../actions/types';

const INITIAL_STATE = {
  clicked: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_REPORT_ACCORDION:
      return {
        ...state,
        clicked: !state.clicked
      };
    case SET_DEFAULT_REPORT_ACCORDION:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};
