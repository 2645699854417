/**
 * Reducer that manages the state for Conversational Assessment
 */

import _ from 'lodash';

import {
  FETCH_CONVERSATIONAL_ASSESSMENT,
  FETCH_CONVERSATIONAL_ASSESSMENT_DONE,
  FETCH_CONVERSATIONAL_ASSESSMENT_ERROR,
  SET_CONVERSATIONAL_ASSESSMENT_PROGRESS_ID,
  SET_CONVERSATIONAL_ASSESSMENT_DATA,
  CLEAR_CONVERSATIONAL_ASSESSMENT_DATA
} from '../actions/types';

const INITIAL_STATE = {
  isFetching: false,
  questions: {},
  greetingMessages: [],
  hasError: false,
  isCompleted: false,
  progressId: '',
  state: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CONVERSATIONAL_ASSESSMENT:
      return {
        ...state,
        isFetching: true,
        hasError: false
      };
    case FETCH_CONVERSATIONAL_ASSESSMENT_DONE:
      return {
        ...INITIAL_STATE,
        isFetching: false,
        questions: action.data.questions,
        greetingMessages: action.data.greetingMessages,
        progressId: state.progressId
      };
    case FETCH_CONVERSATIONAL_ASSESSMENT_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true
      };
    case SET_CONVERSATIONAL_ASSESSMENT_DATA: {
      const isCompleted = _.get(action, 'payload.isCompleted', false);
      return {
        ...state,
        questions: isCompleted ? {} : _.get(action, 'payload.questions', {}),
        state: _.get(action, 'payload.state', ''),
        isCompleted: isCompleted || false
      };
    }
    case SET_CONVERSATIONAL_ASSESSMENT_PROGRESS_ID:
      return {
        ...state,
        progressId: action.payload
      };
    case CLEAR_CONVERSATIONAL_ASSESSMENT_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
