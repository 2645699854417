let twilioClient = null;
let activeConversation = null;
let chatPaginator = null;

export const setTwilioClient = (client) => {
    twilioClient = client;
}
export const setTwilioActiveConversation = (conversation) => {
    activeConversation = conversation;
}
export const setTwilioChatPaginator = (paginator) => {
    chatPaginator = paginator;
}

export const getTwilioClient = () => twilioClient;
export const getTwilioActiveConversation = () => activeConversation;
export const getTwilioChatPaginator = () => chatPaginator;

