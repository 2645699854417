import {
  GET_CONVERSATION_TOKEN,
  SET_CONVERSATION_TOKEN,
  GET_CONVERSATION_INFO,
  SET_CONVERSATION_INFO,
  GET_CONVERSATION_ERROR,
  SET_PARTICIPANTS,
  CLEAR_CONVERSATION_DATA,
  INITIALIZE_CONVERSATION_DONE,
  INITIALIZE_CONVERSATION_START,
  INITIALIZE_CONVERSATION_ERROR
} from '../actions/types';

const INITIAL_STATE = {
  info: {},
  participantsMap: {},
  token: '',
  isProcessing: false,
  hasError: false
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONVERSATION_INFO:
      return {
        ...state,
        info: action.data,
        hasError: false,
        hasInitializationError: false,
        isProcessing: false
      };
    case SET_CONVERSATION_TOKEN:
      return {
        ...state,
        token: action.data,
        hasError: false,
        hasInitializationError: false,
        isProcessing: false
      };
    case GET_CONVERSATION_INFO:
      return {
        ...state,
        hasError: false,
        hasInitializationError: false,
        isProcessing: true
      };
    case GET_CONVERSATION_ERROR:
      return {
        ...state,
        hasError: true,
        hasInitializationError: false,
        isProcessing: false
      };
    case GET_CONVERSATION_TOKEN:
      return {
        ...state,
        ...action.data,
        hasError: false,
        hasInitializationError: false,
        isProcessing: true
      };
    case SET_PARTICIPANTS:
      return {
        ...state,
        participantsMap: action.data
      };
    case CLEAR_CONVERSATION_DATA:
      return {
        ...state,
        info: {},
        participantsMap: {},
        token: '',
        isProcessing: false,
        hasInitializationError: false,
        hasError: false
      };
    case INITIALIZE_CONVERSATION_START:
      return {
        ...state,
        isProcessing: true,
        info: {},
        participantsMap: {},
        token: '',
        hasInitializationError: false,
        hasError: false
      };

    case INITIALIZE_CONVERSATION_DONE:
      return {
        ...state,
        isProcessing: false,
        hasInitializationError: false,
        hasError: false
      };
    case INITIALIZE_CONVERSATION_ERROR:
      return {
        ...state,
        hasInitializationError: true,
        isProcessing: false,
        hasError: true
      };
    default:
      return state;
  }
};
