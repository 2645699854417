/* eslint-disable import/no-cycle */
import moment from 'moment-timezone';
import { get, has, isEmpty, size } from 'lodash';
import { obfuscateProps } from 'logger-customization';
import { parse, stringify } from 'tiny-querystring';
import featureService from './feature-service';
import coachRequestStatusTypes from '../assets/data/dashboard/coachRequestStatusTypes';
import store, { dispatch } from '../store';
import history from './history';
import { getUserDetails, isLoggedIn } from './auth';
import logger from './logger';
import config from '../config';
import screenTypes from '../assets/data/self-use/screenTypes';
import storage from './storage';
import storeKeys from './storage/store-keys';
import { getDurationOfLastAssessmentAndToday } from '../containers/Callback/Callback.utils';
import { platformInfo } from './platformInfo';
import { getParsedValue } from './storage/helpers';

const {
  primaryLogoPath,
  secondaryLogoPath,
  defaultLogoPath,
  cdnContentUrl,
  retakeAssessmentForEligibilityDuration,
  showInAppPolicy,
  carePlanExpiry,
  appName
} = config;

/**
 * helper methods, hopefully many more to come
 */

const levels = [
  'low',
  'minimal',
  'mild',
  'moderate',
  'moderatelySevere',
  'high',
  'severe'
];

/*
rootPaths handles back button navigation and check if current path is home/root
- "relative" paths contains path parameters or subpath and extraction is required
- "absolute" paths do not have any path parameters and no extraction is required
*/
const rootPaths = {
  relative: ['report', 'coach', 'my-plan', 'self-use'],
  absolute: ['resources', 'account']
};

const surveyPaths = ['assessment', 'diagnostic'];

class Helpers {
  levelSortFunc = (a, b) => levels.indexOf(b.level) - levels.indexOf(a.level);

  getMaskedData = (data) => {
    if (!isEmpty(data)) {
      return `****${data.substr(data.length - 3)}`;
    }
    logger.info('Data to be masked is empty', 'helpers.getMaskedData');
    return data;
  };

  unreadMessageSortFunc = (a, b) => {
    if (a.unread > b.unread) {
      return -1;
    }
    if (b.unread > a.unread) {
      return 1;
    }
    return 0;
  };

  getSingleCategoryReports = (reports = []) => {
    // For cca and cascade, old reports contain all goals inside categories object, so filtering only single goal reports
    if (
      featureService._hasIntelliPlatformFeature() &&
      !featureService._hasCatAssessmentFeature()
    ) {
      return reports.filter((report) => size(report.categories) === 1);
    }
    return reports;
  };

  getLastReportId = () => {
    let { reports } = store.getState().reportList;
    reports = this.getSingleCategoryReports(reports);
    let lastReportId;
    const hasReports = reports.length > 0;
    if (hasReports) {
      lastReportId = reports[0]._id;
    }
    return lastReportId;
  };

  getConnectionType = () => {
    const {
      companyDetails: { connectionType }
    } = store.getState().companyDetails;
    return connectionType;
  };

  getCurrentGoalAndSeverity = () => {
    const { goalId, severity } = store.getState().dashBoard;
    return {
      goalId,
      severity
    };
  };

  isCoach = () => {
    const { roles } = getUserDetails();
    return !!roles && roles.includes('coach');
  };

  isAdmin = () => {
    const { roles } = getUserDetails();
    return !!roles && roles.includes('admin');
  };

  isCarePlanExpired = (timestamp) => {
    if (!timestamp) return true;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (currentTimestamp - timestamp > carePlanExpiry) return true;
    return false;
  };

  shouldFetchCarePlan = () => {
    const { cards, timestamp } = store.getState().carePlan;
    if (
      featureService._hasCarePlanPlanFeature() &&
      (isEmpty(cards) || this.isCarePlanExpired(timestamp))
    ) {
      return true;
    }
    return false;
  };

  redirectAfterLogin = () => {
    const { loginRedirect } = store.getState();
    if (loginRedirect) {
      history.replace(loginRedirect);
    } else if (this.isAdmin()) {
      // TODO: Remove it once we move away from Admin UI
      history.replace('/admin');
    } else if (this.isCoach() && featureService._hasCoachChatOnWebFeature()) {
      history.replace('/coach/chat');
    } else {
      history.replace(this.homeUrl());
    }
  };

  goBack = () => {
    if (history.length === 1) {
      logger.info(
        'Pushing home URL as we do not have any history, probably got here via deeplinks',
        'helpers.goBack'
      );
      return history.push(this.homeUrl());
    }
    return history.goBack();
  };

  getPageFromPath = (path = '/') => path.split('/')[1];

  homeUrl = () => {
    let homeUrl = featureService._hasIntelliPlatformFeature()
      ? '/assessment/conversational'
      : '/intro';
    const lastReportId = this.getLastReportId();
    const { goalId, severity } = store.getState().dashBoard;
    // Check if there is any last report Id and set the redirect url
    // to the report details page
    if (this.isCoach()) {
      homeUrl = '/coach/chat';
    } else if (lastReportId) {
      if (featureService._hasMyPlanFeature()) {
        homeUrl =
          !isEmpty(goalId) && !isEmpty(severity)
            ? `/my-plan/goal/${goalId}/severity/${severity}`
            : '/my-plan';
      } else if (
        featureService._hasRflAssessmentFeature() ||
        featureService._hasSelfUseFeature()
      ) {
        homeUrl = `/self-use/goal/${goalId}`;
      } else {
        homeUrl = `/report/${lastReportId}`;
      }
      const page = this.getPageFromPath(homeUrl);
      logger.info(
        `User has already taken the assessment redirecting to ${page} page`,
        'helpers.homeUrl',
        { homeUrl, lastReportId }
      );
    }
    return homeUrl;
  };

  getRedirectUrlResultCard = ({ goalId }) => {
    let redirectUrl = '/my-plan';
    if (featureService._hasLatestAssessmentReportFeature()) {
      redirectUrl = `/report/${this.getLastReportId()}`;
    } else if (this.isCoach()) {
      redirectUrl = '/coach/chat';
    } else if (
      !featureService._hasMyPlanFeature() &&
      featureService._hasSelfUseFeature()
    ) {
      redirectUrl = `/self-use/goal/${goalId}`;
    }
    logger.info(
      `Redirecting user from result card to redirectUrl: ${redirectUrl}`,
      'helpers.getRedirectUrlResultCard'
    );
    return redirectUrl;
  };

  sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  createInitials = (name) => {
    if (!name) {
      return '';
    }
    const nameInitials = name.match(/\b(\w)/g);
    if (!isEmpty(nameInitials)) {
      return nameInitials.join('').substring(0, 3);
    }
    return '';
  };

  createInitialsCoach = (firstName, lastName, fullName) => {
    if (firstName && lastName) {
      const initials = `${firstName.charAt(0)}${lastName.charAt(
        0
      )}`.toUpperCase();
      return initials;
    }
    // This is the fallback case when the lastName is not present
    if (firstName) {
      const words = firstName.split(' ');
      if (words.length === 1) {
        return words[0].substring(0, 2).toUpperCase();
      }
      if (words.length >= 2) {
        const firstInitial = words[0][0];
        const lastInitial = words[words.length - 1][0];
        return (firstInitial + lastInitial).toUpperCase();
      }
    }
    // This is the fallback case when the lastName and firstName are not present
    if (fullName) {
      const words = fullName.split(' ');
      if (words.length === 1) {
        return words[0].slice(0, 2).toUpperCase();
      }
      if (words.length >= 2) {
        return `${words[0].charAt(0)}${words[words.length - 1].charAt(
          0
        )}`.toUpperCase();
      }
    }
    logger.error(
      'Could not find firstName , lastName , fullName',
      'helpers.abbreviateCoachName'
    );
    return '';
  };

  abbreviateCoachName = (firstName, lastName, fullName) => {
    if (firstName && lastName) {
      const lastNameInitial = lastName[0];
      return `${firstName} ${lastNameInitial}.`;
    }
    // This is the fallback case when the lastName is not present
    if (firstName) {
      return firstName;
    }
    // This is the fallback case when the lastName and firstName are not present
    if (fullName) {
      const words = fullName.split(' ');
      if (words.length === 1) {
        return fullName;
      }
      if (words.length >= 2) {
        const firstWord = words[0];
        const lastNameInitial = words[words.length - 1][0];
        return `${firstWord} ${lastNameInitial}.`;
      }
    }
    logger.error(
      'Could not find firstName , lastName , fullName',
      'helpers.abbreviateCoachName'
    );
    return '';
  };

  transformBySeverity = (goals) => {
    logger.info(
      'Transforming goals by severity',
      'helpers.transformBySeverity'
    );
    const goalsSeverityMap = {};
    Object.keys(goals).forEach((key) => {
      const index = goals[key].level;
      if (goalsSeverityMap[index]) goalsSeverityMap[index].push(key);
      else goalsSeverityMap[index] = [key];
    });
    return goalsSeverityMap;
  };

  getActivePath = () => {
    const {
      location: { pathname }
    } = history;
    return pathname.trim().toLowerCase();
  };

  isActivePath = (path) => {
    const activePath = this.getActivePath();
    return activePath.split('/')[1] === path;
  };

  setPageTitle = (pageTitle) => {
    if (isEmpty(pageTitle)) {
      document.title = appName;
    }
    document.title = pageTitle;
  };

  getLocalTime = (date = null) =>
    moment(date || new Date())
      .tz(moment.tz.guess())
      .format('MM/DD/YYYY HH:mm:ss z Z');

  getCompanyLogo = async (companyDetails) => {
    const audienceType = await storage.getItem(storeKeys.AUDIENCE_TYPE, true);
    let defaultPath;
    if (audienceType === 'rfl') {
      defaultPath = secondaryLogoPath;
    } else if (audienceType === 'commercial') {
      defaultPath = primaryLogoPath;
    } else {
      defaultPath = defaultLogoPath;
    }
    const logoPath = get(companyDetails, 'logoPath', defaultPath);
    return `${cdnContentUrl}${logoPath}`;
  };

  getSupportedScreenTypes = () => Object.values(screenTypes);

  isRootPath = () => {
    const { absolute, relative } = rootPaths;
    const currentPath = this.getActivePath();
    const pathName = currentPath.split('/')[1];
    // We need report route check due to multiple home tabs i.e Goal and My plan
    if (pathName === 'report' && featureService._hasIntelliPlatformFeature()) {
      return false;
    }
    // TODO: We can handle the tabs from Route level.
    if (pathName === 'self-use' && currentPath.split('/')[4]) {
      return false;
    }
    if (currentPath.includes('breathing-exercise')) {
      return false;
    }
    return (
      absolute.includes(currentPath.substring(1)) || relative.includes(pathName)
    );
  };

  isFirstAssessmentScreen = () => {
    const currentPath = this.getActivePath();
    const { reports } = store.getState().reportList;
    const pathName = currentPath.split('/')[1];
    return surveyPaths.includes(pathName) && isEmpty(reports);
  };

  canShowTabs = () =>
    isLoggedIn() && !platformInfo.isDesktop && this.isRootPath();

  canShowBackButton = () =>
    isLoggedIn() &&
    !platformInfo.isDesktop &&
    !this.isRootPath() &&
    (this.getLastReportId() || this.isFirstAssessmentScreen());

  canShowPolicyBackButton = (currentPath) =>
    showInAppPolicy && currentPath.includes('terms');

  getUserCompanyDetail = async () => {
    let accessCode = '';
    let companyDetail = {};

    try {
      const storeData = store.getState();

      accessCode = await storage.getItem(storeKeys.ACCESS_CODE, true);
      const storedCompanyDetail = await storage.getItem(
        storeKeys.COMPANY_DETAILS,
        true
      );
      const companyList = get(storeData, 'companyList.companyList');
      if (!accessCode) {
        logger.warn(
          'Unable to get access code',
          'helpers.getUserCompanyDetail'
        );
      } else if (storedCompanyDetail) {
        companyDetail = getParsedValue(storedCompanyDetail);
      } else if (!isEmpty(companyList)) {
        companyDetail = companyList.find(
          (company) =>
            company.alias.toLowerCase() === accessCode.trim().toLowerCase()
        );
      }
    } catch (error) {
      logger.warn(
        `An error occurred while fetching user company detail - ${accessCode}`,
        'helpers.getUserCompanyDetail',
        {
          error,
          accessCode,
          companyDetail
        }
      );
    }
    return {
      companyDetail,
      accessCode
    };
  };

  checkAndAssignAutoCoachForEligibility = (
    reports,
    partner,
    coachRequestStatus,
    startAutoCoachAssign
  ) => {
    if (
      !this.isCoach() &&
      featureService._hasCoachAccessFeature() &&
      featureService._hasEligibilityAssessmentFeature() &&
      !isEmpty(reports) &&
      coachRequestStatus === coachRequestStatusTypes.none
    ) {
      const diff = getDurationOfLastAssessmentAndToday('day', reports);
      const autoAssignCoachForEligibility =
        diff < parseInt(retakeAssessmentForEligibilityDuration, 10);
      if (autoAssignCoachForEligibility) {
        dispatch(
          startAutoCoachAssign({
            mode: 'auto',
            eapName: partner.name,
            goals: this.transformBySeverity(reports)
          })
        );
      }
    }
  };

  executeOnEnter = (event, handler) => {
    if (event.code === 'Enter') {
      handler();
    }
  };

  executeOnSpace = (event, handler) => {
    if (event.code === 'Space') {
      handler();
    }
  };

  getTimeStamp = (properties) => {
    if (properties) {
      return has(properties, 'timestamp')
        ? properties.timestamp
        : moment().locale('en').utc().format();
    }
    return moment().locale('en').utc().format();
  };

  isCoachTabAccessible = () => {
    const isCoachAccount =
      this.isCoach() && featureService._hasCoachChatOnWebFeature();
    if (isCoachAccount) {
      return true;
    }
    const {
      requestCoach: { coachRequestStatus } = {},
      carePlan: { cards = [] } = {}
    } = store.getState();
    const coachCardDetails = cards.find((data) => data.type === 'coach');
    const isCoachAssigned =
      featureService._hasCoachAccessOnWebFeature() &&
      coachRequestStatus === coachRequestStatusTypes.assigned;

    if (featureService._hasCarePlanPlanFeature()) {
      return isCoachAssigned && !isEmpty(coachCardDetails);
    }
    return isCoachAssigned;
  };

  obfuscateUrl = (url) => {
    if (!isEmpty(url)) {
      // substring(1) to get string after first character -> '?'
      const queryString = new URL(url).search.substring(1);
      const queryParams = parse(queryString);
      if (!isEmpty(queryParams)) {
        const obfuscatedQueryParams = obfuscateProps(queryParams);
        const obfuscatedQueryString = stringify(obfuscatedQueryParams);
        return url.replace(queryString, obfuscatedQueryString);
      }
    }
    return url;
  };
}
export default new Helpers();
