/**
 * App force update modal
 */

// core
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { ActionButton } from 'components/ui/Buttons';
import Lang from 'components/Lang';
// utils
import { redirectToStore } from './AppForceUpdate.utils';
// styles
import styles from './AppForceUpdate.module.scss';

const { appForceUpdateModal, updateButtonStyle } = styles;

const AppForceUpdate = () => (
  <div data-testid="app-force-update-container">
    <Dialog open>
      <DialogContent>
        <div
          className={appForceUpdateModal}
          data-testid="app-force-update-modal"
        >
          <h2 data-testid="app-force-update-header">
            <Lang path="appForceUpdatePopup.header" />
          </h2>
          <p data-testid="app-force-update-description">
            <Lang path="appForceUpdatePopup.description" />
          </p>
          <ActionButton
            className={updateButtonStyle}
            testId="app-force-update-button"
            onClick={redirectToStore}
          >
            <Lang path="appForceUpdatePopup.buttonText" />
          </ActionButton>
        </div>
      </DialogContent>
    </Dialog>
  </div>
);

export default AppForceUpdate;
