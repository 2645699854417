/**
 * Reducer for managing video player modal state
 */

import { OPEN_VIDEO_MODAL, CLOSE_VIDEO_MODAL } from '../../actions/types';

const INITIAL_STATE = {
  isOpen: false,
  url: null,
  videoId: null,
  analyticsMessage: '',
  showTranscript: false,
  vttUrl: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_VIDEO_MODAL:
      return {
        isOpen: true,
        url: action.url,
        videoId: action.videoId,
        analyticsMessage: action.analyticsMessage,
        autoPlay: action.autoPlay,
        showTranscript: action.showTranscript,
        vttUrl: action.vttUrl
      };
    case CLOSE_VIDEO_MODAL:
      return {
        ...state,
        isOpen: false,
        videoId: null,
        analyticsMessage: '',
        showTranscript: false,
        vttUrl: ''
      };
    default:
      return state;
  }
};
