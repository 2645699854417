import moment from 'moment';
import {
  GET_COMPANY_LIST_START,
  GET_COMPANY_LIST_DONE,
  GET_COMPANY_LIST_ERROR
} from '../actions/types';
// TODO: fix this dependency cycle issue
// eslint-disable-next-line import/no-cycle
import config from '../../config';

const { companyListTtl } = config;

const INITIAL_STATE = {
  companyList: [],
  companyListTtl: moment(0),
  hasError: false,
  isLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_LIST_START:
      return {
        ...state,
        hasError: false,
        isLoading: true
      };
    case GET_COMPANY_LIST_DONE:
      return {
        ...state,
        companyList: action.data,
        companyListTtl: moment().add(companyListTtl, 'milliseconds'),
        isLoading: false
      };
    case GET_COMPANY_LIST_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false
      };
    default:
      return state;
  }
};
