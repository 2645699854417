import { TOGGLE_SUBTITLES_ENABLED } from '../actions/types';

const INITIAL_STATE = {
  enabled: false // can have more state like subtitle language
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SUBTITLES_ENABLED:
      return {
        ...state,
        enabled: !state.enabled
      };
    default:
      return state;
  }
};
